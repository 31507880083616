var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('router-link', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "to": {
        name: 'trust.part-e',
        query: {
          trust_id: this.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/left-arrow.png",
      "height": "20",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "m-0",
    staticStyle: {
      "padding-left": "10px",
      "padding-top": "2px",
      "display": "inline-block"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("back-to")) + " " + _vm._s(_vm.$t("receiver-list")) + " ")])])], 1), _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2 mt-3"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " " + _vm._s(_vm.$t("amendment.receiver-info")) + " "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy"
    }],
    staticClass: "mb-1",
    attrs: {
      "content": "Waris yang akan dihubungi jika berlaku kematian",
      "height": "20",
      "src": "/images/icon/info-button.png"
    }
  })]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.name.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("name")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.name.$model,
      expression: "$v.form.name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.name.$error && !_vm.$v.form.name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.name")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.relationship.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("relationship")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.relationship.$model,
      expression: "$v.form.relationship.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.relationship, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changeRelationship($event);
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.relationship")) + " --")]), _vm._l(_vm.relationships, function (relationship) {
    return _c('option', {
      key: relationship,
      domProps: {
        "value": relationship
      }
    }, [_vm._v(" " + _vm._s(relationship) + " ")]);
  })], 2), _vm.$v.form.relationship.$error && !_vm.$v.form.relationship.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("relationship")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.ic_number.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("mykad")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "id": "ic_number",
      "mask": ['######-##-####']
    },
    model: {
      value: _vm.$v.form.ic_number.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.ic_number, "$model", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "$v.form.ic_number.$model"
    }
  }), _vm.$v.form.ic_number.$error && !_vm.$v.form.ic_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad")) + " ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.email.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("email")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "lowercase",
      rawName: "v-lowercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.email.$model,
      expression: "$v.form.email.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "email"
    },
    domProps: {
      "value": _vm.$v.form.email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.email, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.email.$error && !_vm.$v.form.email.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("home-telno")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.home_phone_number,
      expression: "form.home_phone_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "userstate"
    },
    domProps: {
      "value": _vm.form.home_phone_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "home_phone_number", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.email.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("mobile-no")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.mobile_number.$model,
      expression: "$v.form.mobile_number.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "mobile_no"
    },
    domProps: {
      "value": _vm.$v.form.mobile_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.mobile_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.mobile_number.$error && !_vm.$v.form.mobile_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mobile-no")) + " ")]) : _vm._e()])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.address.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "address"
    }
  }, [_vm._v(_vm._s(_vm.$t("mailing-address"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.sameAddress,
      expression: "form.sameAddress"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "type": "checkbox",
      "id": "customCheck1",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.sameAddress) ? _vm._i(_vm.form.sameAddress, "1") > -1 : _vm.form.sameAddress
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.form.sameAddress,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "sameAddress", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "sameAddress", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "sameAddress", $$c);
        }
      }, _vm.copyAddress]
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "customCheck1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.same-address")) + " ")])]), _c('textarea', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.address.$model,
      expression: "$v.form.address.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "id": "address_2",
      "rows": "2",
      "readonly": _vm.form.sameAddress == 1
    },
    domProps: {
      "value": _vm.$v.form.address.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.address, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.address.$error && !_vm.$v.form.address.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("address")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "postcode"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('span', {
    staticStyle: {
      "text-decoration": "underline",
      "font-size": "11px",
      "color": "#333"
    },
    on: {
      "click": _vm.openpostcode
    }
  }, [_vm._v(_vm._s(_vm.$t("add-new")))])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy",
      value: _vm.form.postcode,
      expression: "form.postcode",
      modifiers: {
        "lazy": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "postcode",
      "readonly": _vm.form.sameAddress == 1
    },
    domProps: {
      "value": _vm.form.postcode
    },
    on: {
      "blur": function ($event) {
        return _vm.getPostcode(1);
      },
      "change": function ($event) {
        return _vm.$set(_vm.form, "postcode", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "city"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.city,
      expression: "form.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "city",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.state,
      expression: "form.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "state",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "state", $event.target.value);
      }
    }
  })])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.mykad.frontUrl.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("mykadcopy-front")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.mykad.frontUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.mykad.frontUrl + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e(), !_vm.mykad.frontUrl ? _c('label', {
    staticClass: "upload-photo-dark text-center",
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("select-copy")))])]) : _vm._e(), _c('input', {
    staticClass: "upload-photo-input",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo-2",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files, 'mykad-front');
      }
    }
  })])]), _vm.$v.mykad.frontUrl.$error && !_vm.$v.mykad.frontUrl.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("document")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.mykad.backUrl.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("mykadcopy-back")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.mykad.backUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.mykad.backUrl + ');',
    attrs: {
      "for": "upload-photo-1"
    }
  }) : _vm._e(), !_vm.mykad.backUrl ? _c('label', {
    staticClass: "upload-photo-dark text-center",
    attrs: {
      "for": "upload-photo-1"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("select-copy")))])]) : _vm._e(), _c('input', {
    staticClass: "upload-photo-input",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo-1",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files, 'mykad-back');
      }
    }
  })])]), _vm.$v.mykad.backUrl.$error && !_vm.$v.mykad.backUrl.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("document")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])])])])]), _c('b-modal', {
    ref: "modalPostcode",
    staticClass: "modal fade",
    attrs: {
      "title": _vm.$t('add-newpostcode'),
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "ok": _vm.storePostcode
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.postcode,
      expression: "formPostcode.postcode"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.formPostcode.postcode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "postcode", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("city")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.city,
      expression: "formPostcode.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formPostcode.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "city", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("state")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.state,
      expression: "formPostcode.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formPostcode.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "state", $event.target.value);
      }
    }
  })])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }